<template>
  <div class="footer">
    <div class="footer-left">
      <div class="footer-item">
        <img src="@/assets/img/logo2.png" style="width: 60px" />
        <div class="footer-item__top">
          <router-link to="/">
            <div class="footer-item__top-item">首页服务</div>
          </router-link>
          <router-link to="/about/introduce">
            <div class="footer-item__top-item">关于我们</div>
          </router-link>
          <router-link to="/contact_us">
            <div class="footer-item__top-item">招贤纳士</div>
          </router-link>
          <router-link to="/help">
            <div class="footer-item__top-item">用户帮助</div>
          </router-link>
        </div>
        <div class="footer-item__bottom">
          版权所有@2022长沙老铁汽车服务有限公司
          <span
            ><a href="https://beian.miit.gov.cn/" target="_blank">
              湘ICP备2021019770号-1</a
            ></span
          >
        </div>
        <div class="page" style="display: flex; align-items: end">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302001474"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
            ><img
              src="@/assets/img/beian.png"
              style="float: left; height: 20px; width: 20px"
            />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              湘公网安备 43010302001474号
            </p></a
          >
        </div>
      </div>
    </div>
    <div class="footer-right">
      <div class="footer-item2">
        <img src="@/assets/img/xz.png" style="width: 100px" />
        <div>用户下载</div>
      </div>
      <div class="footer-item1">
        <div>联系电话</div>
        <div>0731-82232822</div>
        <div>周一至周日8:30-17:30</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer {
  padding: 80px 0 54px;
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer-item__top {
    cursor: pointer;
    display: flex;
    .footer-item__top-item {
      margin-right: 100px;
      margin-top: 20px;
    }
  }
  .footer-item__bottom {
    margin: 20px 0;
    color: #8990a0;
    font-size: 14px;
    span {
      padding-left: 16px;
    }
  }
  .footer-right {
    display: flex;
    // align-items: center;
    .footer-item2 {
      display: inline-block;
      text-align: center;
      line-height: 34px;
      color: #8990a0;
    }
    .footer-item1 {
      margin-left: 100px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div:nth-child(1) {
        font-size: 14px;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
      }
      div:nth-child(3) {
        font-size: 12px;
      }
    }
  }
}
</style>