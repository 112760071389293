<template>
  <div class="container">
    <div class="callAs">
      <div class="callAs-logo">
        <img src="../assets/img/kefu.png" alt="" />
      </div>
      <div class="callAs-center">
        <div>
          <img src="../assets/img/phone3.png" alt="联系方式" />
          <span>联系电话</span>
        </div>
        <div class="callAs-phone">
          <div class="p1">(0731)</div>
          <div class="p2">82232822</div>
        </div>
        <div class="callAs-btn">欢迎咨询</div>
      </div>
    </div>
    <div class="callAs-top"> 
      <div @click="goTop">
        <img src="@/assets/img/calltop.png" class="callAs-top--active" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTop() { 
      document.body.scrollTop = document.documentElement.scrollTop = 0; 
    },
  },
};
</script>

<style lang="less">
.container {
  position: fixed;
  bottom: 200px;
  right: 0;
  // box-shadow: 0px 8px 14px 0px rgba(7, 86, 240, 0.08);
  border-radius: 15px 0 0 15px;
  z-index: 1;
  .callAs {
    width: 118px;
    height: 228px;
    background: #397bff;
    border-radius: 59px 59px 0px 0px;
    .callAs-logo {
      width: 104px;
      height: 104px;
      margin: 10px auto;
      img {
        margin: 10px auto;
        width: 104px;
        height: 104px;
      }
    }
    .callAs-center {
      // margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 9px;
      }
      .callAs-phone {
        color: #fff;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .p2 {
          font-size: 18px;
        }
      }
      .callAs-btn {
        width: 94px;
        height: 32px;
        background: #ffffff;
        box-shadow: 0px 5px 10px 0px rgba(57, 123, 255, 0.35);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #397bff;
        cursor: pointer;
      }
    }
  }
  .callAs-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    img {
      width: 38px;
    }
  }
}
</style>