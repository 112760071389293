<template>
  <div class="home-title" :class="{ 'home-title-shadow': !isTop }">
    <div class="home-title__box">
      <router-link to="/">
        <div class="home-title__box-logo">
          <img src="@/assets/img/logo2.png" />
        </div>
      </router-link>
      <div class="home-title__box-menu">
        <router-link to="/">
          <div
            class="home-title__box-menu__item home-title__box-action"
            :class="{
              'home-action': $route.path != $route.meta.home,
            }"
          >
            首页服务
          </div>
        </router-link>
        <!-- <router-link to="security">
          <div
            class="home-title__box-menu__item home-title__box-action"
            :class="{ 'home-action': $route.path !== '/security' }"
          >
            安全办公
          </div>
        </router-link> -->
        <div
          class="home-title__box-menu__item home-title__box-action"
          :class="{
            'home-action': $route.path != $route.meta.about,
          }"
        >
          <a-popover placement="bottom" v-model="visible" trigger="hover">
            <template slot="content">
              <div class="home-title__call-box">
                <div class="image">
                  <img src="@/assets/img/gywm1.jpg" alt="" srcset="" />
                </div>
                <div class="image">
                  <img src="@/assets/img/gywm2.jpg" alt="" srcset="" />
                </div>
                <div class="about">
                  <router-link to="/about/introduce">
                    <div class="intro">
                      <a-icon type="right" style="font-size: 12px" /><span
                        >公司介绍</span
                      >
                    </div>
                  </router-link>
                  <router-link to="/about/connection">
                    <div class="contact">
                      <a-icon type="right" style="font-size: 12px" /><span
                        >联系我们</span
                      >
                    </div>
                  </router-link>
                </div>
              </div>
            </template>
            <span class=" ">关于我们</span>
          </a-popover>
        </div>

        <!-- <a-dropdown>
         <div class="home-title__box-menu__item home-title__box-action" 
            :class="{'home-action': $route.path !== '/commerce' && $route.path !== '/security' }"> 
            <div class="home-title__box-menu__item home-title__box-action" 
            :class="{'home-action': $route.path !== '/security' }">
              安全办公
          </div>
          <a-menu slot="overlay">
            <a-menu-item>
              <router-link to="security" class="">
                安全办公
              </router-link>
            </a-menu-item>
             <a-menu-item>
              <a href="javascript:;">云客服</a>
            </a-menu-item> 
             <a-menu-item>
              <router-link to="commerce" class="">
                跨境电商
              </router-link>
            </a-menu-item> 
          </a-menu>
          </div>
        </a-dropdown> -->
        <router-link to="/contact_us">
          <div
            class="home-title__box-menu__item home-title__box-action"
            :class="{ 'home-action': $route.path !== '/contact_us' }"
          >
            招贤纳士
          </div>
        </router-link>
        <router-link to="/help">
          <div
            class="home-title__box-menu__item home-title__box-action"
            :class="{ 'home-action': $route.path !== '/help' }"
          >
            用户帮助
          </div>
        </router-link>
      </div>
      <div class="home-title__seach" style="color: #222">
        电话：0731-82232822
      </div>
      <!--<div class="home-title__seach" ref="seatTipOperation">
         <a-button @click="dowm">
          产品下载
        </a-button> 
      </div>-->
      <!-- <div class="home-title__call">
        <a-popover placement="bottom" v-model="visible" trigger="click">
          <template slot="content">
            <div class="home-title__call-box">
              <p><a-icon type="phone" />  (0571) 88773523</p>
              <p><a-icon type="mail" />  ds@dsphere.cn</p>
            </div>
          </template>
          <span class="">
            联系我们<a-icon type="down" :class="{'home-title__call-icon': visible}" />
          </span>
        </a-popover>
      </div> -->
      <!-- <div class="home-title__button" @click="goConsole">
        企业登录
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isTop: true, // 是否置顶
      visible: false,
    };
  },
  methods: {
    goConsole() {
      window.open("https://work.dsphere.com.cn/#/login");
    },
    dowm() {
      window.open(
        "https://dsphere-agent.oss-cn-hangzhou.aliyuncs.com/CBEC/DSphereSetup.exe"
      );
    },
  },
};
</script>

<style lang="less">
.home-title {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height: 70px;
  width: 100%;
  background: #fff;
  z-index: 10;
  a {
    color: #0756f0;
  }
}
.home-title-shadow {
  box-shadow: 0 4px 8px #ddd;
}
.home-title__box {
  margin: 0 auto;
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
}
.home-title__box-logo {
  // width: 120px;
  font-size: 20px;
  font-weight: 500;
  img {
    width: 50px;
  }
}
.home-title__box-menu {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 50px;
}
.home-title__box-menu__item {
  position: relative;
  padding: 0 20px;
  text-align: center;
  color: #0756f0;
  font-weight: 500;
  font-size: 16px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 20px;
    height: 4px;
    background: linear-gradient(270deg, #118ff9 0%, #0756f0 100%);
    border-radius: 2px;
  }
}
.home-title__box-action {
  height: 60px;
  line-height: 60px;
  cursor: pointer;
}
.home-title__box-action:hover {
  background: #0756f0;
  color: #fff;
  a {
    color: #fff !important;
  }
  &::after {
    content: "";
    background: #0756f0;
  }
}
.home-title__seach {
  flex: 1;
  text-align: right;
  button {
    width: 108px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 2px solid #0756f0;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0756f0;
  }
}
.home-title__call {
  margin: 0 60px;
  cursor: pointer;
  i {
    margin-left: 5px;
    font-size: 10px;
  }
}
.home-title__call-box {
  width: 920px;
  // height: 238px;
  padding: 30px;
  display: flex;
  .image {
    width: 306px;
    height: 170px;
    background: #f1f1f1;
    margin-right: 10px;
  }
  .about {
    margin: 20px 0 0px 40px;
    .intro {
      margin-bottom: 10px;
    }
    span {
      margin-left: 5px;
    }
  }
}
.home-title__call-icon {
  transform: rotate(180deg);
}
.home-title__seach .ant-input {
  width: 200px;
  margin-right: 20px;
}
.home-action {
  color: #222;
  a {
    color: #666;
    &:hover {
      color: #666;
    }
  }
  &::after {
    content: "";
    background: #fff;
  }
}
.home-title__button {
  width: 100px;
  margin-left: 20px;
  border-left: 1px solid #e8e8e8;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #011847;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
</style>