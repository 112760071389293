<template>
  <div class="home">
    <div class="home-banner">
      <a-carousel
        autoplay
        arrows
        effect="fade"
        dotsClass="security-box-dot"
        :after-change="afterChange"
      >
        <a slot="customPaging" slot-scope="props">
          <div
            class="home-banner__box--bot__item"
            :class="{
              'home-banner__box--bot__item-action': active === props.i,
            }"
            @click="active = props.i"
          ></div>
        </a>

        <img src="@/assets/banner.jpg" alt="banner" />
        <img src="@/assets/banner1.jpg" alt="banner" />
        <img src="@/assets/banner2.jpg" alt="banner" />
      </a-carousel>
    </div>

    <div class="home-details">
      <div class="home-deitail__left">
        <div class="home-details__left-top"></div>
        <br />
        <div class="home-details__left-bottom"></div>
      </div>
      <div class="home-details__center">
        <div>通过老铁车帮惠，养车</div>
        <div class="home-details__center-dot">省时</div>
        <div class="home-details__center-dot">省力</div>
        <div class="home-details__center-dot">省心</div>
        <div class="home-details__center-dot">省钱</div>
        <div>还能</div>
        <div class="home-details__center-dot">赚钱</div>
      </div>
      <div class="home-deitail__right">
        <div class="home-details__right-top"></div>
        <br />
        <div class="home-details__right-bottom"></div>
      </div>
    </div>

    <div class="home-product">
      <div class="home-product__title">平台特色介绍</div>
      <div class="home-product__title-gang"></div>
      <div class="home-product__center">
        <div class="home-product__center-item" @click="goCommerce('factory')">
          <img
            src="@/assets/img/product3.png"
            class="home-product__center-item__img"
          />
          <div class="home-product__center-item__title">工厂门店</div>
          <div class="home-product__center-item__ps">
            全长沙768+ <br />全城包围 <br />方便
          </div>
          <div class="home-product__center-item__go">
            <img src="@/assets/img/go.png" />
          </div>
        </div>

        <div
          class="home-product__center-item"
          @click="goCommerce('inspection')"
        >
          <img
            src="@/assets/img/product2.png"
            class="home-product__center-item__img"
          />
          <div class="home-product__center-item__title">年检服务网点</div>
          <div class="home-product__center-item__ps">
            六区一县 <br />18个服务网点 <br />等等
          </div>
          <div class="home-product__center-item__go">
            <img src="@/assets/img/go.png" />
          </div>
        </div>

        <div
          class="home-product__center-item"
          @click="goCommerce('multimedia')"
        >
          <!-- <img
            src="@/assets/img/product.png"
            class="home-product__center-item__img"
          /> -->
          <div style="width: 248px;margin:30px 40px;">
            <video
              src="@/assets/img/video.mp4"
              width="248px"
              height="180px" 
              controls
            ></video>
          </div>
          <div class="home-product__center-item__title">多媒体宣传</div>
          <div class="home-product__center-item__ps">
            融消费者、供应商、服务商于一体，增强商家竞争优势让消费者在消费中省钱还能赚钱。
          </div>
          <div class="home-product__center-item__go">
            <img src="@/assets/img/go.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="cost">
      <!-- <div class="home-titleps">
        <div class="home-product__title">
          申请演示
        </div>
        <div class="home-product__title-gang"></div>
      </div>

      <div class="homecost-video">
        <img src="homesqys.png" class="homecost-video-img"/>
        <img src="play2.png" class="homecost-video__play" @click="goPay"/>
      </div> -->

      <div class="home-titleps">
        <div class="home-product__title">业务覆盖</div>
        <div class="home-product__title-gang"></div>
      </div>

      <div class="home-industry">
        <div
          class="home-industry--item"
          v-for="item in industryList"
          :key="item.name"
        >
          <img class="home-industry--item-img" :src="item.icon" />
          <div class="home-industry--item-title">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="home-bottom">
      <div class="home-product__title">
        打造高效、快捷、智能、优质的线上线下服务体系
      </div>
      <div class="home-product__title-gang"></div>
      <div class="home-bottom__button">
        <!-- <a-button type="primary" class="home-bottom__button-left">
          立即注册
        </a-button> -->
        <!-- <a-button class="home-bottom__button-right" @click="dowm">
          立即下载
        </a-button> -->
      </div>
    </div>
    <!-- <div class="home-bottom__next">
      跨境电商解决方案
      <a-button @click="goCommerce">了解详情</a-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      industryList: [
        {
          icon: "homeds.png",
          name: "车管业务",
        },
        {
          icon: "homehlw.png",
          name: "车辆保险",
        },
        {
          icon: "homeqy.png",
          name: "维修保养",
        },
        {
          icon: "homejr.png",
          name: "道路救援",
        },
        {
          icon: "homezzy.png",
          name: "二手车",
        },
        {
          icon: "homejy.png",
          name: "优惠加油",
        },
      ],
    };
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    afterChange(current) {
      this.active = current;
    },
    goPay() {
      this.$router.push("/contact_us");
    },
    goSecurity() {
      this.$router.push("/security");
    },
    // goCommerce () {
    //   this.$router.push('/commerce')
    // },
    goCommerce(url) {
      this.$router.push(url);
    },
    dowm() {
      window.open(
        "https://dsphere-agent.oss-cn-hangzhou.aliyuncs.com/CBEC/DSphereSetup.exe"
      );
    },
  },
};
</script>

<style lang="less">
.home {
  background: #fff;
}
.home-banner {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  color: #fff;
}
.home-banner__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.home-banner__backbox {
  position: relative;
  padding: 0 0 60px;
  height: 600px;
  background: url("../../assets/img/homebanner.png") no-repeat top left;
  background-size: 100% 100%;
}
.home-banner__backbox1 {
  position: relative;
  padding: 0 0 60px;
  height: 600px;
  background: url("../../assets/img/homebanner2.png") no-repeat top left;
  background-size: 100% 100%;
}
.home-banner__backbox2 {
  position: relative;
  padding: 0 0 60px;
  height: 600px;
  background: url("../../assets/img/homebanner3.png") no-repeat top left;
  background-size: 100% 100%;
}
.home-banner__box {
  margin: 0 auto;
  width: 1200px;
  color: #fff;
  p {
    margin: 28px 0 31px;
    font-size: 26px;
    span {
      color: #4a85f9;
    }
  }
}
.home-banner__box-title {
  margin-top: 148px;
  font-size: 60px;
  font-weight: bold;
  line-height: 130px;
}
.home-banner__box-p {
  margin: 8px 0 47px;
  display: flex;
  font-size: 24px;
  line-height: 40px;
}
.home-banner__box-p__span {
  margin: 0 10px;
  border-right: 3px solid #fff;
  transform: scale(0.5);
}
.home-banner__box--buttton {
  width: 138px;
  height: 48px;
  background: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 26px;
  text-align: center;
  line-height: 48px;
  color: #0756f0;
  cursor: pointer;
}
.home-banner__box--bot {
  margin: 4vw 0 0;
}
.home-banner__box--bot__item {
  position: relative;
  left: -560px;
  top: -40px;
  margin-right: 5px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.home-banner__box--bot__item-action {
  background: #fff;
}

.home-titleps {
  margin-bottom: 50px;
  text-align: center;
}
.home-titleps__top {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.home-titleps__gang {
  margin-bottom: 10px;
  display: inline-block;
  width: 30px;
  height: 4px;
  background: #4a85f9;
}
.home-titleps__center {
  margin: 0 auto;
  width: 800px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}
.home-details {
  position: relative;
  margin: -70px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  border-radius: 16px;
  height: 150px;
  width: 1200px;
  z-index: 1;
  box-shadow: 0px 8px 14px 0px rgba(49, 114, 242, 0.1);
  font-size: 32px;
  color: #012f8a;
  line-height: 80px;
  .home-deitail__left {
    margin-left: 4%;
    line-height: 20px;
  }
  .home-details__left-top {
    display: inline-block;
    width: 48px;
    height: 8px;
    background: linear-gradient(205deg, #118ff9 0%, #0756f0 100%);
    border-radius: 3px;
    opacity: 0.8;
  }
  .home-details__left-bottom {
    display: inline-block;
    width: 32px;
    height: 8px;
    background: linear-gradient(205deg, #118ff9 0%, #0756f0 100%);
    border-radius: 3px;
    opacity: 0.8;
  }
  .home-deitail__right {
    margin-right: 4%;
    text-align: right;
    line-height: 20px;
  }
  .home-details__right-top {
    display: inline-block;
    width: 48px;
    height: 8px;
    background: linear-gradient(205deg, #118ff9 0%, #0756f0 100%);
    border-radius: 3px;
    opacity: 0.8;
  }
  .home-details__right-bottom {
    display: inline-block;
    width: 32px;
    height: 8px;
    background: linear-gradient(205deg, #118ff9 0%, #0756f0 100%);
    border-radius: 3px;
    opacity: 0.8;
  }
  .home-details__center {
    display: flex;
    height: 80px;
    font-size: 24px;
  }
  .home-details__center-dot {
    margin: 0 16px;
    width: 80px;
    height: 80px;
    background: #0756f0;
    border-radius: 80px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    line-height: 80px;
    text-align: center;
  }
}
.home-product__title-gang {
  margin: 24px auto 81px;
  width: 140px;
  height: 12px;
  background: linear-gradient(270deg, rgba(17, 143, 249, 0.5) 0%, #0756f0 100%);
  border-radius: 5px;
  opacity: 0.8;
}
.home-product__title {
  padding-top: 28px;
  text-align: center;
  font-size: 42px;
}

.home-product {
  margin-top: 100px;
  background: #fff;
  .home-product__center {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .home-product__center-item {
    height: 492px;
    width: 320px;
    background: url("../../assets/img/productbackground.png") no-repeat top left;
    background-size: 100% 100%;
    cursor: pointer;
    .home-product__center-item__img {
      margin: 29px 32px 40px 48px;
      width: 240px;
    }
    .home-product__center-item__title {
      margin-left: 40px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 26px;
      color: #fff;
    }
    .home-product__center-item__ps {
      margin-left: 40px;
      width: 240px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-size: 18px;
      color: #fff;
    }
    .home-product__center-item__go {
      margin: 40px 25px 0 40px;
      background: #fff;
      padding: 11px 8px;
      width: 40px;
      border-radius: 4px;
      font-size: 0;
      text-align: center;
      img {
        width: 24px;
        height: 17px;
      }
    }
    &:hover .home-product__center-item__go {
      width: auto;
    }
  }
}

.cost {
  padding: 60px 0;
}
.cost-box {
  margin: 0 auto 100px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.cost-box__item {
  width: 263px;
  text-align: center;
  img {
    width: 82px;
  }
}
.cost-box__item-title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.cost-box__item-center {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}
.homecost-video {
  width: 100%;
  margin: -60px auto 100px;
  padding: 90px 0;
  position: relative;
  background: url("../../assets/img/homebanner1.png") no-repeat top left;
  background-size: auto 100%;
  text-align: center;
  .homecost-video-img {
    width: 70%;
  }
}
.homecost-video__play {
  position: absolute;
  top: 30vw;
  right: 25%;
  width: 120px;
  cursor: pointer;
}
.home-industry {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  img {
    width: 360px;
  }
}
.home-industry--item-title {
  margin: 16px 0 74px;
  font-size: 24px;
  font-family: PingFang SC;
  color: #0756f0;
}
.home-industry--item {
  width: 33%;
  img {
    width: 187px;
    height: 187px;
  }
}
.ant-carousel .slick-dots-bottom {
  bottom: 110px !important;
}
.ant-carousel .security-box-dot li button {
  background: #666 !important;
  border: 1px solid #fff;
}
.ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 30px !important;
  color: rgba(0, 0, 0, 0.3) !important;
}
.ant-carousel .slick-prev {
  color: rgba(255, 255, 255, 0.3) !important;
}
.ant-carousel .custom-slick-arrow:before {
  display: none;
}
.ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 30px;
}
.home-bottom {
  width: 100%;
  margin: 0 auto;
  padding: 53px 0 80px;
  background: #fff url("../../assets/img/homebottom.png") no-repeat bottom left;
  background-size: 100%;

  .home-bottom__button {
    margin: 0 auto;
    width: 540px;
    display: flex;
    justify-content: center;
    button {
      width: 240px;
      height: 84px;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 500;
    }
    .home-bottom__button-left {
      background: #0756f0;
    }
    .home-bottom__button-right {
      color: #0756f0;
      border: 4px solid #0756f0;
    }
  }
}
.home-bottom__next {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 53px 0;
  background: url("../../assets/img/homebanner.png") no-repeat top left;
  background-size: 100%;
  text-align: center;
  color: #fff;
  font-size: 42px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  button {
    position: absolute;
    top: 63px;
    right: 50%;
    transform: translate(500px, 0);
    width: 130px;
    height: 44px;
    background: #ffffff;
    border-radius: 8px;
    color: #0756f0;
    font-size: 24px;
  }
}
</style>