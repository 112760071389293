// npm i axios  不需要的一些功能删去即可
import Vue from 'vue'
import axios from 'axios' 

// 配置基地址
axios.defaults.baseURL = process.env.VUE_APP_BASEURL

// 设置axios, 允许携带cookies
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 把token加入请求头中, 不需要可以删除下面4句代码, 还有上面引入token的代码
   
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(
    function(response) {
        // 对响应数据做点什么
        // 把response的data返回给客户端
        return response.data
    },
    function(error) {
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

Vue.prototype.axios = axios
 